import React from 'react'
import './landingpage.css'

const landingpage = () => {

  

  return (
    <div align="center" className='sectionstyle'>
      <video   loop={true} data-autoplay data-keepplaying  autoPlay muted  className='renders' src="/assets/a.mp4" type="video./mp4" />
    </div>
  )
}

export default landingpage