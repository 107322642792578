import React from 'react';
import Landingpages from "./landingpage/landingpage";

const body = () => {
    return (
      <div>
        <Landingpages />
      </div>
      );
}

export default body